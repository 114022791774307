<template>
  <div class="ad-consume padding-20 order-detail box-shadow-light-grey">
    <!-- 条件搜索 -->
    <div class="filter-box">
      <!-- <h4 class="filter-title">今日广告</h4> -->
      <div class="row">
        <exclude-query :params="params" />
        <select-dept-user :params="params" />
        <el-select
          v-model="params.accountChannelId"
          filterable
          style="width: 180px"
          reserve-keyword
          placeholder="选择公众号"
        >
          <el-option
            v-for="(item, index) in officialList"
            :label="item.channelName"
            :value="item.id"
            :key="index"
          >
          </el-option>
        </el-select>
        <el-select
          v-model="params.booksId"
          filterable
          remote
          clearable
          style="width: 150px"
          reserve-keyword
          placeholder="书籍名称"
          :remote-method="getBook"
        >
          <el-option
            v-for="item in bookList"
            :key="item.id"
            :label="item.bookName"
            :value="item.id"
          >
          </el-option>
        </el-select>

        <el-select
          v-model="params.isTest"
          clearable
          placeholder="类型"
          style="width: 100px"
        >
          <el-option label="普通号" :value="0"></el-option>
          <el-option label="测书号" :value="1"></el-option>
        </el-select>
        <select-field
          :fieldsData="fieldsData"
          :originFeild="originFeild"
          :EchoField="sendField"
          @fieldChange="handleFieldChange"
        />
        <el-button
          type="primary"
          @click="handlePageChange(1)"
          style="margin-left: 10px"
          :loading="loading"
        >
          搜索
        </el-button>
        <el-button type="info" @click="handleReset" :loading="loading">
          重置
        </el-button>
      </div>
    </div>
    <div class="data-box" style="height: 690px; overflow: auto">
      <!-- 小说成本线性图 -->
      <div
        v-loading="loadingGraph"
        id="costReChargeGraph"
        :style="'width: 99%;height: 300px;z-index:1;'"
      ></div>
      <!-- 税前税后-- -->
      <el-tabs type="card" v-model="tabName">
        <el-tab-pane label="税后" name="first">
          <el-table
            header-row-class-name="table-header"
            ref="userConsumeTable"
            class="consume-table"
            :data="dataList"
            style="width: 100%"
            height="600px"
            v-loading="loading"
            empty-text="暂无数据"
            @sort-change="handleSortChange"
          >
            <el-table-column
              v-for="(item, index) in afterTaxFilFields"
              :key="index"
              :sortable="sortableType(item)"
              :align="filAlign(item)"
              :prop="item.fieldCode"
              :label="item.fieldName"
              :min-width="item.width + 'px' || '100px'"
            >
              <template slot-scope="scope">
                <div v-if="item.hasChild">
                  <div
                    v-for="(item2, index2) in filChildrenFieds(item.children)"
                    :key="index2"
                    class="children-fieds"
                    :style="
                      item2.fieldCode !== 'avatar' ? 'line-height: 19px' : ''
                    "
                  >
                    <template v-if="item2.fieldCode === 'avatar'">
                      {{
                        scope.row[item2.fieldCode] ? item2.fieldName + ":" : ""
                      }}
                      <img
                        v-if="scope.row[item2.fieldCode]"
                        class="order-avatar"
                        :src="scope.row[item2.fieldCode]"
                      />
                    </template>
                    <template v-else>
                      {{
                        scope.row[item2.fieldCode] && item2.fieldName !== ""
                          ? item2.fieldName + ":"
                          : ""
                      }}
                      <span :style="'color:' + item.fontColor">{{
                        scope.row[item2.fieldCode]
                      }}</span>
                    </template>

                    <!-- <span class="primary-num">{{ scope.row[item2.fieldCode] }}</span> -->
                  </div>
                </div>
                <span v-else :style="'color:' + item.fontColor">{{
                  scope.row[item.fieldCode] | sortField(item)
                }}</span>
              </template>
            </el-table-column>
          </el-table>
        </el-tab-pane>
        <el-tab-pane label="税前" name="second">
          <el-table
            header-row-class-name="table-header"
            ref="userConsumeTable"
            class="consume-table"
            :data="dataList"
            style="width: 100%"
            height="600px"
            v-loading="loading"
            empty-text="暂无数据"
            @sort-change="handleSortChange"
          >
            <el-table-column
              v-for="(item, index) in beforeTaxFilFields"
              :key="index"
              :sortable="sortableType(item)"
              :align="filAlign(item)"
              :prop="item.fieldCode"
              :label="item.fieldName"
              :min-width="item.width + 'px' || '100px'"
            >
              <template slot-scope="scope">
                <div v-if="item.hasChild">
                  <div
                    v-for="(item2, index2) in filChildrenFieds(item.children)"
                    :key="index2"
                    class="children-fieds"
                    :style="
                      item2.fieldCode !== 'avatar' ? 'line-height: 19px' : ''
                    "
                  >
                    <template v-if="item2.fieldCode === 'avatar'">
                      {{
                        scope.row[item2.fieldCode] ? item2.fieldName + ":" : ""
                      }}
                      <img
                        v-if="scope.row[item2.fieldCode]"
                        class="order-avatar"
                        :src="scope.row[item2.fieldCode]"
                      />
                    </template>
                    <template v-else>
                      {{
                        scope.row[item2.fieldCode] && item2.fieldName !== ""
                          ? item2.fieldName + ":"
                          : ""
                      }}
                      <span :style="'color:' + item.fontColor">{{
                        scope.row[item2.fieldCode]
                      }}</span>
                    </template>

                    <!-- <span class="primary-num">{{ scope.row[item2.fieldCode] }}</span> -->
                  </div>
                </div>
                <span v-else :style="'color:' + item.fontColor">{{
                  scope.row[item.fieldCode] | sortField(item)
                }}</span>
              </template>
            </el-table-column>
          </el-table>
        </el-tab-pane>
      </el-tabs>
      <!-- 税前税后-- -->
      <!-- 表格数据 -->

      <!-- 分页 -->
      <el-pagination
        style="margin-top: 10px; text-align: right"
        background
        @current-change="handlePageChange"
        :current-page="page"
        :page-size="pageSize"
        layout="total, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
      <!-- 公共抽屉 -->
      <common-drawer
        :showDrawer="showCommonDrawer"
        size="90%"
        @close="showCommonDrawer = false"
        :template="showTemplate"
        :nextParams="nextParams"
      />
    </div>
  </div>
</template>

<script>
import { formatThousandsDots } from "@/assets/js/utils";
import ExcludeQuery from "@/components/excludeQuery";
import SelectDeptUser from "@/components/SelectDeptUser";
import echarts from "echarts";
import SelectField from "@/components/SelectField";
import { getBookList } from "@/api/account";
import {
  getMultiCostRecharge,
  getFields,
  getMultiCostRechargeGraph,
} from "@/api/statics";
import {
  yesterdayOptions,
  labelList,
  pickerOptions,
} from "@/assets/js/options";
import { mapActions, mapGetters } from "vuex";
import CommonDrawer from "@/components/commonDrawer.vue";
export default {
  name: "channelConsume",
  props: {
    lastParams: Object,
  },
  components: {
    CommonDrawer,
    SelectField,
    ExcludeQuery,
    SelectDeptUser,
  },
  computed: {
    ...mapGetters(["userList", "officialList"]),
    filFields: function () {
      // var _that = this
      const tmpFilFields = this.showField.filter(function (item) {
        return item.autoFields !== 1;
      });
      return tmpFilFields;
    },
    beforeTaxFilFields: function () {
      var _that = this;
      const tmpFilFields = this.showField.filter(function (item) {
        return (
          item.autoFields !== 1 && _that.beforeTax.includes(item.fieldCode)
        );
      });
      return tmpFilFields;
    },
    afterTaxFilFields: function () {
      var _that = this;
      const tmpFilFields = this.showField.filter(function (item) {
        return item.autoFields !== 1 && _that.afterTax.includes(item.fieldCode);
      });
      return tmpFilFields;
    },
    // 排序处理
    sortableType() {
      return function (value) {
        if (value.autoFields === 0) {
          return false;
        } else if (value.autoFields === 2 || value.autoFields === 4) {
          return true;
        } else if (value.autoFields === 1 || value.autoFields === 3) {
          return "custom";
        }
      };
    },
    filChildrenFieds() {
      return function (value) {
        // var _that = this
        return value.filter(function (item) {
          // 过滤首页展示字段
          return item.autoFields === 0;
        });
      };
    },
    // 文字对齐处理
    filAlign() {
      return function (value) {
        switch (value.fontAlign) {
          case 0:
            return "left";
          case 1:
            return "center";
          case 2:
            return "right";
          default:
            return "center";
        }
      };
    },
  },
  filters: {
    sortField: function (value, item) {
      if (item.autoFields === 4 || item.autoFields === 5) {
        if (value !== undefined) {
          return formatThousandsDots(value.toString());
        }
      } else {
        return value;
      }
    },
  },
  watch: {
    lastParams: function () {
      this.getData("drawer");
    },
  },
  data() {
    return {
      params: {},
      bookList: null,
      fieldsData: [],
      originFeild: [],
      selectFields: null,
      selectAllFields: false,
      statistic: {},
      dataList: [],
      totalData: [],
      loading: false,
      loadingGraph: false,
      sortName: null,
      sortDesc: null,
      page: 1,
      total: 0,
      pageSize: 31,
      todayAdData: {},
      showRecharge: false,
      yesterdayOptions,
      labelList,
      sendField: [],
      showField: [],
      tmpShowField: [],
      showCommonDrawer: false,
      showTemplate: null,
      nextParams: {},
      pickerOptions,
      chargingChart: null,
      costReChargeGraph: null,
      tabName: "first",
      beforeTax: [
        "selectDate",
        "totalCostAmount",
        "totalReturnRate",
        "costAmount",
        "returnRate",
        "totalAmount",
        "amount",
        "oldRechargeAmount",
        "oldOderAmount",
      ],
      afterTax: [
        "selectDate",
        "totalCashCostAmount",
        "totalAfterTaxAmount",
        "totalReturnRate",
        "cashCostAmount",
        "afterTaxAmount",
        "returnRate",
        "rechargeAmount",
        "oldOderAfterTaxAmount",
      ],
    };
  },
  methods: {
    // 线形图数据处理
    // 小说成本线性图
    setOptionCostRechargeGraph({ x, y }) {
      const seriesData = y.map((item) => {
        return {
          name: item.name,
          data: item.data.reverse(),
          type: "line",
          smooth: true,
        };
      });
      this.costReChargeGraph.setOption({
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "cross",
            label: {
              backgroundColor: "#6a7985",
            },
          },
        },
        legend: {
          data: y.map((item) => item.name),
        },
        toolbox: {
          feature: {
            saveAsImage: {},
          },
        },
        grid: {
          left: "3%",
          right: "3%",
          bottom: "3%",
          containLabel: true,
        },
        xAxis: [
          {
            type: "category",
            boundaryGap: false,
            data: x.reverse(),
          },
        ],
        yAxis: [
          {
            type: "value",
          },
        ],
        series: seriesData,
      });
    },
    // 初始化线形图
    initGraph() {
      this.costReChargeGraph = echarts.init(
        document.getElementById("costReChargeGraph")
      );

      window.onresize = this.costReChargeGraph.resize;
    },
    // 表格点击事件
    ...mapActions({
      addRouterHistory: "addRouterHistory",
    }),
    // 组件字段变化
    // handleFieldChange (value) {
    //   if (value.length === 0) {
    //     this.showField = this.originFeild
    //   } else {
    //     this.showField = value
    //   }
    //   this.$nextTick(() => {
    //     this.$refs.channelConsumeTable.doLayout()
    //   })
    // },
    // 获取书籍列表
    getBook(bookName) {
      getBookList({
        page: 1,
        pageSize: 50,
        bookName,
      }).then((res) => {
        this.bookList = res.list;
      });
    },
    // 排序处理
    handleSortChange({ column, prop, order }) {
      if (column.sortable !== "custom") {
        return;
      }
      this.sortName = prop;
      this.sortDesc = order === "descending";
      this.params.sortName = this.sortName;
      this.params.sortDesc = this.sortDesc;
      this.handlePageChange(1);
    },
    // 重置操作
    handleReset() {
      this.page = 1;
      this.params.adminUserId = null;
      this.params.deptId = null;
      this.params.accountChannelId = null;
      this.params.booksId = null;
      this.params.ruledOutBooksIds = [];
      this.params.ruledOutAccountChannelIds = [];
      this.params.isTest = null;
      this.originPlatform = null;
      this.orderId = null;
      this.orderType = null;
      this.beginDate = null;
      this.endDate = null;
      this.adminUserId = null;
      this.originNovel = null;
      this.sortName = null;
      this.sortDesc = null;
      this.sendField = [];
      this.showField = this.originFeild;
      this.tmpShowField = [];
      this.handlePageChange(1);
      // this.showField = this.originFeild;
    },
    // 分页操作
    handlePageChange(page) {
      this.page = page;
      this.getData();
    },
    // 选择字段变化
    handleFieldChange(send, show) {
      // if (value.length === 0) {
      //   this.showField = this.originFeild;
      // } else {
      //   this.showField = value;
      // }
      // 请求格式field
      this.sendField = send;
      // 展示格式field
      this.tmpShowField = show;
    },
    // 请求前处理字段
    handleFields(item) {
      if (item.length === 0) {
        return null;
      }
      let tmpSend = [];
      const _that = this;
      tmpSend = item.reduce(function (a, b) {
        return a.concat(b);
      });
      // 判断勾选项 推入其下所有子级
      for (let i = 0; i < tmpSend.length; i++) {
        const flagIndex = _that.originFeild.findIndex((item2) => {
          return item2.fieldCode === tmpSend[i];
        });
        if (flagIndex !== -1 && "children" in _that.originFeild[flagIndex]) {
          _that.originFeild[flagIndex].children.forEach((item3) => {
            tmpSend.push(item3.fieldCode);
          });
        }
      }
      // 去重
      tmpSend = Array.from(new Set(tmpSend));
      return tmpSend.join(",");
    },
    // 广告数据获取
    getData(flag) {
      // this.params.queryDate ? '' : (this.params.queryDate = tody)
      this.loading = true;
      this.loadingGraph = true;
      // 处理后的请求字段 如果选择过字段则使用选择字段sendfield 否则传null
      let tmpFields = null;
      if (this.sendField.length > 0) {
        tmpFields = this.handleFields(this.sendField);
      } else {
        this.showField = this.originFeild;
      }
      let tmpform = {};
      // 如果是创建过程 参数默认取缓存参数 否则使用筛选参数
      if (flag === "created") {
        tmpform = {
          page: this.page,
          pageSize: this.pageSize,
          adminUserId: this.params.adminUserId,
          deptId: this.params.deptId,
          accountChannelId: this.params.accountChannelId,
          booksId: this.params.booksId,
          ruledOutBooksIds:
            this.params.ruledOutBooksIds && this.params.ruledOutBooksIds.length
              ? this.params.ruledOutBooksIds
              : null,
          ruledOutAccountChannelIds:
            this.params.ruledOutAccountChannelIds &&
            this.params.ruledOutAccountChannelIds.length
              ? this.params.ruledOutAccountChannelIds
              : null,
          isTest: this.params.isTest,
          sortName: this.sortName,
          sortDesc: this.sortDesc,
          showFieldCodes: tmpFields,
        };
      } else if (flag === "drawer") {
        tmpform = {
          ...this.lastParams,
        };

        this.params = { ...this.lastParams };
      } else {
        tmpform = {
          page: this.page,
          pageSize: this.pageSize,
          adminUserId: this.params.adminUserId,
          deptId: this.params.deptId,
          accountChannelId: this.params.accountChannelId,
          booksId: this.params.booksId,
          ruledOutBooksIds:
            this.params.ruledOutBooksIds && this.params.ruledOutBooksIds.length
              ? this.params.ruledOutBooksIds.map((item) => item.id).join()
              : null,
          ruledOutAccountChannelIds:
            this.params.ruledOutAccountChannelIds &&
            this.params.ruledOutAccountChannelIds.length
              ? this.params.ruledOutAccountChannelIds.join()
              : null,
          isTest: this.params.isTest,
          sortName: this.sortName,
          sortDesc: this.sortDesc,
          showFieldCodes: tmpFields,
        };
      }
      // 表格数据获取
      getMultiCostRecharge(tmpform)
        .then((res) => {
          this.total = res.total;
          // 如果用户选择过字段则表格展示用户选择字段
          if (this.tmpShowField.length > 0) {
            this.showField = this.tmpShowField;
          }
          this.dataList = res.list;
          // 如果不是抽屉 缓存参数
          if (flag !== "drawer") {
            this.addRouterHistory({
              path: this.$route.path,
              params: {
                ...tmpform,
                cacheShowField: this.showField,
                cacheSendField: this.sendField,
                selectTime: this.params.selectTime,
              },
            });
          }
        })
        .finally(() => (this.loading = false));
      // 线形图数据获取
      getMultiCostRechargeGraph(tmpform)
        .then((res) => {
          this.setOptionCostRechargeGraph(res);
        })
        .finally(() => {
          this.loadingGraph = false;
        });
    },
    // 获取字段
    handleGetFeilds(flag = "created") {
      getFields({ dataType: "costRecharge" }).then((res) => {
        this.originFeild = res;
        const tmpdata = [];
        for (let i = 0; i < res.length; i++) {
          tmpdata.push({
            fieldName: res[i].fieldName,
            fieldCode: res[i].fieldCode,
          });
        }
        this.fieldsData = tmpdata;
        // 若显示字段为空则显示全部
        if (this.showField.length === 0) {
          this.showField = res;
        }
        this.selectAllFields = true;
        this.getData(flag);
        // 判断字段所选长度是否为0 是则全选 否则不全选
      });
    },
    // 处理字段
  },
  created() {
    // this.$set(this.params, "selectTime", []);
    this.getBook();
    if (this.lastParams) {
      this.handleGetFeilds("drawer");
      return false;
    }
    // this.params.queryDate = formatTime(new Date(), "yyyy-MM-dd");
    // 缓存获取
    const tmpIndex = this.$store.state.routerHistory.findIndex(
      (item) => item.path === this.$route.path
    );
    // 若存在缓存
    if (tmpIndex !== -1) {
      this.params = this.$store.state.routerHistory[tmpIndex].params;
      // 获取缓存展示字段
      this.showField =
        this.$store.state.routerHistory[tmpIndex].params.cacheShowField;
      // 获取缓存发送前格式字段
      this.sendField =
        this.$store.state.routerHistory[tmpIndex].params.cacheSendField;
    }
    this.handleGetFeilds();
    // this.getData()
  },

  mounted() {
    this.initGraph();
  },
  beforeCreate() {
    this.$options.components.CommonDrawer =
      require("@/components/commonDrawer").default;
  },
  // 销毁echart不销毁会存在内存溢出
  destroyed() {
    if (this.chargingChart) {
      echarts.dispose(this.chargingChart);
    }
  },
};
</script>

<style lang="scss" scoped>
.ad-consume {
  background-color: #fff;
  border-radius: 6px;
}
// 筛选条件框
.filter-box {
  border-radius: 6px;
  // border: 1px solid #eceff2;
  .filter-title {
    color: $main-text;
    margin-bottom: 20px;
    .iconfont {
      font-size: 12px !important;
    }
  }
}
.order-avatar {
  width: 40px;
  height: 40px;
  vertical-align: middle;
  border-radius: 40px;
}
.order-detail /deep/ tr td {
  font-size: 12px !important;
}
</style>
